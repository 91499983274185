<template>
    <div v-show="show">
        <div class="details">
            <el-row :gutter="20">
                <el-col :span="1">
                    <i class="el-icon-back" style="font-size: 30px" @click="close" />
                </el-col>
                <el-col :span="3">
                    <h4>{{ dataDetail.DeviceName }}</h4>
                </el-col>
                <el-col :span="6">
                    <el-tag :type="
                        dataDetail.Status == 'ONLINE'
                            ? 'success'
                            : dataDetail.Status == 'OFFLINE'
                                ? 'danger'
                                : dataDetail.Status == 'UNACTIVE'
                                    ? 'info'
                                    : 'info'
                    ">
                        {{
                            dataDetail.Status == 'ONLINE'
                                ? '在线'
                                : dataDetail.Status == 'OFFLINE'
                                    ? '离线'
                                    : dataDetail.Status == 'UNACTIVE'
                                        ? '未激活'
                                        : '禁用'
                        }}
                    </el-tag>
                </el-col>
            </el-row>
            <el-descriptions title="设备信息" class="mt20">
                <el-descriptions-item label="设备SN号码">
                    {{ dataDetail.DeviceName }}
                </el-descriptions-item>
                <el-descriptions-item label="设备型号">{{ dataDetail.ProductName }}</el-descriptions-item>
                <el-descriptions-item label="当前状态">
                    {{
                        dataDetail.Status == 'ONLINE' ? '在线' : dataDetail.Status == 'OFFLINE' ? '离线' : dataDetail.Status
                            == 'UNACTIVE' ? '未激活' : '禁用'
                    }}
                </el-descriptions-item>
                <!-- <el-descriptions-item label="IP所在地"
          >{{ dataDetail.Region }}
        </el-descriptions-item>-->
                <el-descriptions-item label="IP地址">
                    {{ dataDetail.IpAddress }}
                </el-descriptions-item>
                <el-descriptions-item label="固件版本">
                    {{ dataDetail.FirmwareVersion }}
                </el-descriptions-item>
                <el-descriptions-item label="创建时间">
                    {{ dataDetail.GmtCreate }}
                </el-descriptions-item>
                <el-descriptions-item label="激活时间">
                    {{ dataDetail.GmtActive }}
                </el-descriptions-item>
                <el-descriptions-item label="最后在线时间">
                    {{ dataDetail.GmtOnline }}
                </el-descriptions-item>
            </el-descriptions>
            <h3 class="m30">系统日志</h3>
            <div>
                <span>
                    设备本地日志记录：
                    <el-switch v-model="statusSwitch" :active-value="1" :inactive-value="0"
                        @change="changeSwitch"></el-switch>
                </span>
                <el-button type="primary" size="small" class="ml20" @click="clickLog">抓取数据</el-button>
                <el-button type="primary" size="small" class="ml20" @click="refresh" plain>刷新</el-button>
            </div>
            <el-table :data="tableData" style="width: 100%" class="mt20" max-height="420">
                <el-table-column prop="create_time" label="抓取时间" />
                <el-table-column prop="remark" label="问题描述">
                    <template slot-scope="scope">
                        {{ scope.row.remark == '' ? '-' : scope.row.remark }}
                        <el-button @click.native.prevent="editRow(scope.row)" type="text" size="small">
                            <i class="el-icon-edit el-icon--right"></i>
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="操作">
                    <template slot-scope="scope">
                        <el-button @click="downFile(scope.row.file_url)" type="text" size="small"
                            :disabled="scope.row.file_url == null">下载日志</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <Pagination :pageSize="10" :total="count" :currentPage="pagesObj.page" @sizeChange="(e) => sizeChange(e)"
                @currentChange="(e) => currentChange(e)" />
        </div>
    </div>
</template>

<script>
import { catchLog, deviceDetail, editRemark, getLogFile, getLogSwitch, logSwitch } from '../../../api/equipment'
import Pagination from '../../../components/Pagination/Pagination.vue'
import { getClientDown } from '../../../utils/ossData'
export default {
    components: { Pagination },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        obj: {
            type: Object,
            default: null,
        },
    },
    watch: {
        show(newVal, oldVal) {
            if (newVal) {
                this.pagesObj.device_name = this.obj.device_name
                this.getDetail()
                this.getSwitch()
                this.getFiles()
            }
        },
    },
    data() {
        return {
            dataDetail: {},
            tableData: [],
            count: 0,
            pagesObj: {
                step: 10,
                page: 1,
            },
            statusSwitch: 0,
        }
    },
    methods: {
        //
        downFile(val) {
            let arr = val.split('/')
            //   配置响应头实现通过URL访问时自动下载文件，并设置下载后的文件名。
            const filename = arr[arr.length - 1]
            const response = {
                'content-disposition': `attachment; filename=${encodeURIComponent(filename)}`,
            }
            // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
            const url = getClientDown.signatureUrl(val.replace('https://zeemr-android-debug.oss-cn-shanghai.aliyuncs.com', ''), { response })
            window.open(url)
        },
        //详情
        async getDetail() {
            let res = await deviceDetail(this.obj)
            if (res.status) {
                this.dataDetail = res.data
            }
        },
        //抓取日志
        async catchLogs(val) {
            let res = await catchLog({
                product_key: this.obj.product_key,
                device_name: this.obj.device_name,
                remark: val,
            })
            if (res.status) {
                if (res.data.success) {
                    this.getFiles()
                }
            } else {

                this.getFiles()
                this.$Message.error(res.msg, 10000000)
            }
        },
        //抓取按钮
        clickLog() {
            const h = this.$createElement
            this.$prompt(h('p', null, [h('i', { style: 'color: red' }, '* '),
            h('span', null, '问题描述：')]), '问题描述', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                closeOnClickModal: false,
                inputPlaceholder: "请输入新问题描述",
                inputPattern: /^.+$/,
                inputErrorMessage: '问题描述为必填',
            })
                .then(({ value }) => {
                    this.catchLogs(value)
                })
                .catch(() => {
                    this.$Message({
                        type: 'info',
                        message: '取消抓取',
                    })
                })
        },
        //编辑问题描述
        editRow(row) {
            const h = this.$createElement
            this.$prompt(h('p', null, [h('i', { style: 'color: red' }, '* '),
            h('span', null, '问题描述：')]), '编辑问题描述', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValue: row.remark,
                closeOnClickModal: false,
                inputPlaceholder: "请输入新问题描述",
                inputPattern: /^.+$/,
                inputErrorMessage: '问题描述为必填',
                beforeClose: async (action, instance, done) => {
                    if (action == 'confirm') {
                        let res = await editRemark({
                            track_id: row.track_id,
                            remark: instance.inputValue,
                        })
                        if (res.status) {
                            this.$Message.success('编辑成功')
                            this.getFiles()
                            done()
                        } else {
                            this.$Message.error(res.msg)
                        }
                    } else {
                        this.$Message({
                            type: 'info',
                            message: '取消修改',
                        })
                        done()
                    }
                },
            })
                .then(async ({ value }) => { })
                .catch(() => { })
        },
        //刷新
        refresh() {
            this.pagesObj.page = 1
            this.getFiles(true)
        },
        //获取日志列表
        async getFiles(type) {
            let res = await getLogFile(this.pagesObj)

            if (res.status) {
                if (type) this.$Message.success('刷新成功')
                this.tableData = res.data.list
                this.count = res.data.count
            } else {
                this.tableData = []
            }
        },
        //日志开关状态
        async getSwitch() {
            let res = await getLogSwitch({ device_name: this.obj.device_name })
            if (res.status) {
                this.statusSwitch = res.data.status
            } else {
                this.statusSwitch = 0
            }
        },
        //日志开关
        async changeSwitch(e) {
            let res = await logSwitch({
                product_key: this.obj.product_key,
                device_name: this.obj.device_name,
                switch: e,
            })
            if (res.status) {
                this.statusSwitch = e;
                this.$Message.success('日志开关状态变更成功')
            } else {
                this.statusSwitch = e == 1 ? 0 : 1;
                this.$Message.error(res.msg)
            }
        },
        //关闭
        close() {
            this.$emit('update:show', false)
        },
        //分页
        sizeChange(e, type) {
            this.pagesObj.step = e
            this.getFiles()
        },
        currentChange(e, type) {
            this.pagesObj.page = e
            this.getFiles()
        },
    },
}
</script>

<style lang="scss">

</style>
<style lang="scss" scoped>
.details {
    width: 96%;
    margin: 20px auto;

    .m30 {
        margin-top: 30px;
        line-height: 50px;
    }

    .ml20 {
        margin-left: 20px;
    }

    .mt20 {
        margin-top: 20px;
    }
}
</style>
