/*
 * @Author: Corn-czy
 * @Date: 2022-05-16 09:41:56
 * @Description: file content
 * @FilePath: \zm-data-platform-admin\src\utils\ossData.js
 */
const OSS = require("ali-oss");
// aliyun 提供数据
const getClient = new OSS({
  region: "oss-cn-shenzhen", // bucket所在的区域
  // 临时访问凭证 临时访问密钥
  accessKeyId: "LTAI5tL5msX9L5RBnPsu9ssu",
  accessKeySecret: "aLoYY9EAYPkhphvrNlPtRuTqcuRFq7",
  bucket: "hsl-zeemr", // bucket名称
});
const getClientDown = new OSS({
  region: "oss-cn-shanghai", // bucket所在的区域
  // 临时访问凭证 临时访问密钥
  accessKeyId: "LTAI5tL5msX9L5RBnPsu9ssu",
  accessKeySecret: "aLoYY9EAYPkhphvrNlPtRuTqcuRFq7",
  bucket: "zeemr-android-debug", // bucket名称
});
export { getClient, getClientDown };
